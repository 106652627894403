@keyframes loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.root {
  display: inline-block;
  margin: auto;
  border: 3px solid transparent;
  border-radius: 50%;
  animation: loading-spinner 1s ease infinite;
  width: 24px;
  height: 24px;
}

// Modifiers
.root {
  &.variantPrimary {
    border-top-color: var(--primary-color);
  }

  &.variantSecondary {
    border-top-color: var(--secondary-color);
  }

  &.variantSuccess {
    border-top-color: var(--success-color);
  }

  &.variantDanger {
    border-top-color: var(--danger-color);
  }

  &.variantWarning {
    border-top-color: var(--warning-color);
  }

  &.variantInfo {
    border-top-color: var(--info-color);
  }
}
