.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: relative;
  background-color: var(--user-drawer-background-color);

  &.waitingForSession {
    align-items: center;
    justify-content: center;

    .contentContainer {
      opacity: 0;
      flex: 0;
      min-height: 0;
    }
  }

  .contentContainer {
    margin-top: var(--nav-bar-height);
  }
}

.toolbar {
  height: var(--nav-bar-height);
  position: fixed;
  width: 250px;
  z-index: 1;
  background-color: var(--user-drawer-toolbar-background-color);

  .link {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: var(--font-weight-bold);

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: var(--user-drawer-toolbar-link-text-color);
      background-color: var(--user-drawer-toolbar-link-background-color);
      text-decoration: none;
    }

    &:active {
      background-color: var(--user-drawer-toolbar-link-background-active-color);
    }

    .icon {
      width: 16px;
      height: 16px;
      margin: 0 24px;
    }
  }

  .logo {
    font-size: var(--font-size-21px, 21px);
    font-weight: var(--font-weight-extra-bold);
    letter-spacing: -1px;
    padding: 0 20px;
    flex: 1;
  }
}

.contentContainer {
  overflow: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  transition: opacity 300ms ease-in-out;
}

.content {
  flex: 1;
}

.typeLabel {
  margin-top: 12px;
}

.user {
  padding: 16px 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .profileLink {
    font-weight: var(--font-weight-bold);
    letter-spacing: -0.3px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0 16px 16px;
    background-color: var(--user-drawer-item-background-color);
    border-radius: var(--border-radius);

    &:link,
    &:visited,
    &:hover,
    &:active {
      color: var(--user-drawer-name-color);
      text-decoration: none;
    }

    &:hover {
      .hover {
        opacity: 1;
      }
    }

    &:active {
      color: var(--user-drawer-name-active-color);
      background-color: var(--user-drawer-item-background-active-color);
    }
  }

  .profilePicture {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    border: 3px solid var(--user-drawer-profile-picture-outer-border-color);
    margin: 0 16px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    .hover {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(0, 0, 0, 50%);

      .icon {
        width: 20px;
        height: 20px;
      }

      .svgIcon {
        fill: var(--user-drawer-profile-picture-icon-color);
      }
    }
  }

  .profilePictureInner {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 3px solid var(--user-drawer-profile-picture-inner-border-color);
    position: relative;
  }

  .name {
    margin-top: 10px;
    text-align: center;
    max-width: 186px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.stats {
  display: flex;
  width: 100%;
  margin-bottom: 8px;
}

.relationshipsStats {
  justify-content: space-evenly;
}

.stat {
  background-color: var(--user-drawer-stat-background-color);
  padding: 8px;
  margin: 0 4px;
  text-align: center;
  position: relative;
  border-radius: var(--border-radius);

  &:first-of-type {
    margin-left: 8px;
  }

  &:last-of-type {
    margin-right: 8px;
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &:active {
    background-color: var(--user-drawer-stat-background-active-color);

    .svgIcon {
      fill: var(--user-drawer-stat-active-color);
    }

    .label {
      color: var(--user-drawer-stat-active-color);
    }
  }

  .value {
    font-size: var(--font-size-14px, 14px);
    color: var(--user-drawer-stat-label-color);
    font-weight: var(--font-weight-bold);
    margin-bottom: 5px;
  }

  .label {
    font-size: var(--font-size-10px, 10px);
    font-weight: var(--font-weight-semi-bold);
    color: var(--user-drawer-stat-color);
    text-transform: uppercase;
  }

  .icon {
    width: 16px;
    height: 16px;
    position: absolute;
    top: 16px;
    right: 16px;
  }

  .svgIcon {
    fill: var(--user-drawer-stat-color);
  }
}

.relationshipsStats .stat {
  width: 50%;
}

.monetizationStats .stat {
  width: 100%;
}

.menu {
  margin: 8px 0 96px;
}

.ctaItems {
  margin-top: 16px;
}

.ctaItem {
  font-size: var(--font-size-14px, 14px);
  font-weight: var(--font-weight-medium);
  display: flex;
  padding: 14px;
  background-color: #f6f6f6;
  margin: 8px;
  justify-content: center;
  text-align: center;
  line-height: 20px;
  border-radius: var(--border-radius);

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &:link,
  &:visited {
    color: var(--user-drawer-cta-item-color);
  }

  &:active {
    background-color: var(--user-drawer-cta-item-background-active-color);
    color: var(--user-drawer-cta-item-active-color);
  }
}

.divider {
  border-top: 1px solid var(--divider-color);
}

// Hover & active states (ignoring mobile)
:global(.canHover) {
  .user {
    .profileLink {
      &:hover {
        color: var(--user-drawer-name-hover-color);
        background-color: var(--user-drawer-item-background-hover-color);
      }

      &:active {
        color: var(--user-drawer-name-active-color);
        background-color: var(--user-drawer-item-background-active-color);
      }
    }
  }

  .stat {
    &:hover {
      background-color: var(--user-drawer-stat-background-hover-color);

      .label {
        color: var(--user-drawer-stat-hover-color);
      }

      .svgIcon {
        fill: var(--user-drawer-stat-hover-color);
      }
    }

    &:active {
      background-color: var(--user-drawer-stat-background-active-color);

      .label {
        color: var(--user-drawer-stat-active-color);
      }

      .svgIcon {
        fill: var(--user-drawer-stat-active-color);
      }
    }
  }

  .toolbar {
    .link {
      // used for the back button
      &:hover {
        background-color: var(
          --user-drawer-toolbar-link-background-hover-color
        );
      }

      &:active {
        background-color: var(
          --user-drawer-toolbar-link-background-active-color
        );
      }
    }
  }

  .ctaItem {
    &:hover {
      background-color: var(--user-drawer-cta-item-background-hover-color);
      color: var(--user-drawer-cta-item-hover-color);
    }

    &:active {
      background-color: var(--user-drawer-cta-item-background-active-color);
      color: var(--user-drawer-cta-item-active-color);
    }
  }
}
