.root {
  font-size: var(--font-size-14px, 14px);
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--menu-item-background-color);
  white-space: nowrap;
}

.link {
  width: 100%;
  height: 100%;
  padding: 0 24px 0 16px;
  display: flex;
  align-items: center;
  text-decoration: none;
  font-weight: var(--font-weight-medium);
  cursor: pointer;

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &:link,
  &:visited {
    color: var(--menu-item-color);
    background-color: var(--menu-item-background-color);
  }

  &:active {
    color: var(--menu-item-active-color);
    background-color: var(--menu-item-background-active-color);
  }

  &:last-of-type {
    border-bottom-width: 0;
  }
}

.icon {
  width: 16px;
  height: 16px;
  margin-right: 16px;
}

.svgIcon {
  fill: var(--menu-item-color);
}

// Modifiers
.root {
  &.variantDanger {
    .title {
      color: var(--danger-color);
    }

    .svgIcon {
      fill: var(--danger-color);
    }
  }
}

// Hover & active states (ignoring mobile)
:global(.canHover) {
  .link {
    &:hover {
      color: var(--menu-item-hover-color);
      background-color: var(--menu-item-background-hover-color);
    }

    &:active {
      color: var(--menu-item-active-color);
      background-color: var(--menu-item-background-active-color);
    }
  }
}
