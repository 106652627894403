#nprogress {
  position: relative;
  z-index: 802;
}

#nprogress .bar {
  background: var(--secondary-color);
  height: 3px;
  top: var(--nav-bar-height);
  z-index: 801;
}

#nprogress .peg {
  box-shadow: none;
}
