.root {
  font-size: var(--font-size-14px, 14px);
  font-weight: var(--font-weight-semi-bold);
  display: flex;
  padding: 16px;

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }

  &:link,
  &:visited {
    color: var(--user-drawer-menu-item-color);
  }

  &:active {
    background-color: var(--user-drawer-menu-item-background-active-color);
    color: var(--user-drawer-menu-item-active-color);

    .svgIcon {
      fill: var(--user-drawer-menu-item-active-color);
    }
  }

  .icon {
    width: 16px;
    height: 16px;
    margin: 0 12px 0 16px;
    position: relative;

    .badge {
      font-size: var(--font-size-10px, 10px);
      font-weight: var(--font-weight-semi-bold);
      background-color: var(--icon-badge-background-color);
      color: var(--icon-badge-text-color);
      position: absolute;
      top: 0;
      padding: 3px 5px;
      border-radius: 10px;
      top: -6px;
      right: -6px;
      min-width: 16px;
      text-align: center;
    }
  }

  .svgIcon {
    fill: var(--user-drawer-menu-item-color);
  }

  .title {
    margin-left: 8px;
  }

  &.selected {
    font-weight: var(--font-weight-bold);

    &:link,
    &:visited,
    &:hover,
    &:active {
      background-color: var(
        --user-drawer-menu-item-background-color
      ) !important;
      color: var(--user-drawer-menu-item-selected-color) !important;
    }

    .svgIcon {
      fill: var(--user-drawer-menu-item-selected-color) !important;
    }
  }
}

// Hover & active states (ignoring mobile)
:global(.canHover) {
  .root {
    &:hover {
      background-color: var(--user-drawer-menu-item-background-hover-color);
      color: var(--user-drawer-menu-item-hover-color);

      .svgIcon {
        fill: var(--user-drawer-menu-item-hover-color);
      }
    }

    &:active {
      background-color: var(--user-drawer-menu-item-background-active-color);
      color: var(--user-drawer-menu-item-active-color);

      .svgIcon {
        fill: var(--user-drawer-menu-item-active-color);
      }
    }
  }
}
