.root {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: var(--nav-bar-height);
}

.footer {
  padding: 20px;
  text-align: center;
  border-top: 1px solid var(--divider-color);
  font-size: var(--font-size-14px, 14px);
  line-height: var(--line-height-for-14px);
}
