@use 'core/styles/breakpoint';

.root {
  position: fixed;
  width: 100%;
  background-color: var(--nav-bar-background-color);
  min-height: var(--nav-bar-height);
  z-index: 800;
  display: flex;
  justify-content: flex-end;
  min-height: var(--nav-bar-height);

  &.waitingForSession {
    .logo,
    .search,
    .links,
    .icons {
      opacity: 0;
    }
  }
}

.logo {
  display: flex;
  justify-content: flex-start;
  transition: opacity 225ms ease-in-out;

  @include breakpoint.abovePx(1200px) {
    //min-width: 300px;
  }
}

.menuLink {
  height: 100%;
  display: flex;
  align-items: center;
  transition: transform 200ms ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }
}

.logoLink {
  height: 100%;
  display: flex;
  align-items: center;
  font-size: var(--font-size-21px, 21px);
  font-weight: var(--font-weight-extra-bold);
  letter-spacing: -1px;
  padding: 0 20px;
  display: none;
  transition: transform 200ms ease-in-out;
  color: #fff;

  &:hover {
    transform: scale(1.025);
    text-decoration: none;
  }

  &:active {
    transform: scale(0.95);
  }

  @include breakpoint.abovePx(770px) {
    display: inherit;
  }
}

/*.search {
  flex: 1;
  display: flex;
  align-items: center;
  transition: opacity 225ms ease-in-out;

  @include breakpoint.above(mobile) {
    flex: 0;
  }
}*/

.flex {
  flex: 1;

  @include breakpoint.abovePx(770px) {
    flex: 0;
  }
}

.links {
  flex: 1;
  align-items: center;
  display: none;
  transition: opacity 225ms ease-in-out;

  @include breakpoint.abovePx(770px) {
    display: flex;
  }
}

.icons {
  display: flex;
  align-items: center;
  transition: opacity 225ms ease-in-out;
}

.icon {
  width: 20px;
  height: 20px;
  margin: 0 20px;
  position: relative;

  .svgIcon {
    fill: var(--nav-bar-icon-color);
  }

  .badge {
    font-size: var(--font-size-10px, 10px);
    font-weight: var(--font-weight-semi-bold);
    background-color: var(--icon-badge-background-color);
    color: var(--icon-badge-text-color);
    position: absolute;
    top: 0;
    padding: 3px 5px;
    border-radius: 10px;
    top: -6px;
    right: -6px;
    min-width: 16px;
    text-align: center;
  }
}

.textLink {
  margin: 0 20px 0 0;
  color: #fff;

  &:first-of-type {
    margin-left: 20px;
  }

  // if changing this, check if the Login/Signup buttons when logged out dont mess up
  //&:last-of-type {
  //  margin-right: 0;
  //}
}

.groupedIcon {
  margin: 0 17px;
}

.user {
  padding: 0 18px;
  transition: transform 100ms ease-in-out;
  height: 100%;
  display: flex;
  align-items: center;

  &:hover {
    transform: scale(1.05);
  }

  &:active {
    transform: scale(0.95);
  }

  &:link,
  &:visited,
  &:hover,
  &:active {
    text-decoration: none;
  }

  .profilePicture {
    font-size: 0;
    border-radius: 50%;
    border: 2px solid var(--nav-bar-profile-picture-outer-border-color);

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid var(--nav-bar-profile-picture-inner-border-color);
    }
  }
}

.loading {
  padding: 20px;
  width: 64px;
}

.loadingColor {
  border-top-color: var(--nav-bar-loading-icon-color) !important;
}

.visuallyHidden {
  display: none;
}

// Hover & active states (ignoring mobile)
/*:global(.canHover) {
  .link {
    &:hover {
      background-color: var(--nav-bar-link-background-hover-color);
    }

    &:active {
      background-color: var(--nav-bar-link-background-active-color);
    }
  }
}*/
