.root {
  display: inline-block;
  transition: opacity 150ms ease-in-out, visibility 150ms ease-in-out,
    transform 150ms ease-in-out;
  opacity: 0;
  visibility: hidden;
  transform: scale(0.75, 0.5);
  box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
    0px 1px 3px 0px rgb(0 0 0 / 10%), 0px 3px 10px 1px rgb(0 0 0 / 10%);
  border-radius: var(--menu-border-radius);
  overflow: hidden;
  background-color: var(--menu-background-color);
  position: absolute;
  min-width: 120px;
  width: auto;
  padding: 8px 0;
  z-index: 1200;
}

.enterActive,
.enterDone {
  visibility: visible;
  opacity: 1;
  transform: scale(1, 1);
}
