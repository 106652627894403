.drawer {
  width: var(--drawer-width);
  position: fixed;
  top: 0;
  transform: translateX(-100%);
  background-color: var(--drawer-background-color);
  bottom: 0;
  height: 100%;
  transition: transform 225ms ease-in-out, box-shadow 225ms ease-in-out;
  will-change: transform;
  z-index: 1001;
  overflow-x: hidden;
  overflow-y: auto;
}

.enterActive,
.enterDone {
  .drawer {
    transform: none;
    box-shadow: 0 0 24px 2px rgb(0 0 0 / 25%);
  }
}

.exitActive {
  .drawer {
    transform: translateX(-100%);
    box-shadow: none;
  }
}

.backdrop {
  background-color: var(--drawer-backdrop-background-color);
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  visibility: hidden;
  z-index: 1000;
  transition: opacity 225ms ease-out;
  user-select: none;
}

.enterActive,
.enterDone {
  .backdrop {
    visibility: visible;
    opacity: 0.5;
  }
}

.exitActive {
  .backdrop {
    visibility: visible;
    opacity: 0;
  }
}

.exitDone {
  .backdrop {
    visibility: hidden;
  }
}
